<template>
  <div class="container">
    <div class="wrap top">
      <input
        type="text"
        placeholder="Search by CIQ ID or Ticker"
        v-model="query"
        v-on:keyup.enter="search"
      />
    </div>
    <div class="wrap middle">
      <h2>{{ this.general['Title'] }}</h2>
      <table>
        <tbody>
          <template v-for="(value, name) in this.general" v-bind:key="name">
            <tr
              v-if="
                name != 'Title' &&
                name != 'Business Description' &&
                name != 'companyName'
              "
            >
              <th class="col-head left">{{ name }}</th>
              <td class="col-body">{{ value }}</td>
            </tr>
          </template>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr v-for="(t, index) in this.crossReference" v-bind:key="index">
            <th class="col-head left" style="width: 15%">
              {{ t.identifierTypeName }}
            </th>
            <td class="col-body">{{ t.identifierTypeValue }}</td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td class="table-name">Business Description</td>
          </tr>
          <tr>
            <td>{{ this.general['Business Description'] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="wrap bottom">
      <div class="left">
        <table id="financials">
          <tbody>
          <tr>
            <td colspan="3" class="table-name">Financial Statement</td>
          </tr>
          <tr>
            <td colspan="3" class="col-head">Balance Sheet</td>
          </tr>
          <tr>
            <th>A mounts in millions, USD</th>
            <th>
              FY{{ this.financials.bs.periodDates[0]?.format('YYYY') }}<br />{{
                this.financials.bs.periodDates[0]?.format('DD-MMM-YYYY')
              }}
            </th>
            <th>
              FY{{ this.financials.bs.periodDates[1]?.format('YYYY') }}<br />{{
                this.financials.bs.periodDates[1]?.format('DD-MMM-YYYY')
              }}
            </th>
          </tr>
          <tr>
            <td class="account">Total Assets</td>
            <td>
              {{ vueNumberFormat(this.financials.bs.totalAssets[0]) }}
            </td>
            <td>
              {{ vueNumberFormat(this.financials.bs.totalAssets[1]) }}
            </td>
          </tr>
          <tr>
            <td class="account">&nbsp;&nbsp;Current Assets</td>
            <td>
              {{ vueNumberFormat(this.financials.bs.currentAssets[0]) }}
            </td>
            <td>
              {{ vueNumberFormat(this.financials.bs.currentAssets[1]) }}
            </td>
          </tr>
          <tr>
            <td class="account">
              &nbsp;&nbsp;&nbsp;&nbsp;Cash, Cash Equivalents
            </td>
            <td>
              {{ vueNumberFormat(this.financials.bs.cashEquivalents[0]) }}
            </td>
            <td>
              {{ vueNumberFormat(this.financials.bs.cashEquivalents[1]) }}
            </td>
          </tr>
          <tr>
            <td class="account">&nbsp;&nbsp;Non-Current Asset</td>
            <td>
              {{
                vueNumberFormat(
                  this.financials.bs.totalAssets[0] -
                    this.financials.bs.currentAssets[0]
                )
              }}
            </td>
            <td>
              {{
                vueNumberFormat(
                  this.financials.bs.totalAssets[1] -
                    this.financials.bs.currentAssets[1]
                )
              }}
            </td>
          </tr>
          <tr>
            <td class="account">Total Liabilities</td>
            <td>
              {{ vueNumberFormat(this.financials.bs.totalLiabilities[0]) }}
            </td>
            <td>
              {{ vueNumberFormat(this.financials.bs.totalLiabilities[1]) }}
            </td>
          </tr>
          <tr>
            <td class="account">&nbsp;&nbsp;Current Liabilities</td>
            <td>
              {{ vueNumberFormat(this.financials.bs.currentLiabilities[0]) }}
            </td>
            <td>
              {{ vueNumberFormat(this.financials.bs.currentLiabilities[1]) }}
            </td>
          </tr>
          <tr>
            <td class="account">&nbsp;&nbsp;Non-Current Liabilites</td>
            <td>
              {{
                vueNumberFormat(
                  this.financials.bs.totalLiabilities[0] -
                    this.financials.bs.currentLiabilities[0]
                )
              }}
            </td>
            <td>
              {{
                vueNumberFormat(
                  this.financials.bs.totalLiabilities[1] -
                    this.financials.bs.currentLiabilities[1]
                )
              }}
            </td>
          </tr>
          <tr>
            <td class="account">Total Equity</td>
            <td>
              {{ vueNumberFormat(this.financials.bs.totalEquities[0]) }}
            </td>
            <td>
              {{ vueNumberFormat(this.financials.bs.totalEquities[1]) }}
            </td>
          </tr>
          <tr>
            <td colspan="3" class="col-head">Income Statement</td>
          </tr>
          <tr>
            <th>Amounts in millions, USD</th>
            <th>
              FY{{ this.financials.is.periodDates[0]?.format('YYYY') }}<br />{{
                this.financials.is.periodDates[0]?.format('DD-MMM-YYYY')
              }}
            </th>
            <th>
              FY{{ this.financials.is.periodDates[1]?.format('YYYY') }}<br />{{
                this.financials.is.periodDates[1]?.format('DD-MMM-YYYY')
              }}
            </th>
          </tr>
          <tr>
            <td class="account">Total Revenues</td>
            <td>
              {{ vueNumberFormat(this.financials.is.totalRevenues[0]) }}
            </td>
            <td>
              {{ vueNumberFormat(this.financials.is.totalRevenues[1]) }}
            </td>
          </tr>
          <tr>
            <td class="account">Cost of Sales</td>
            <td>
              {{ vueNumberFormat(this.financials.is.costOfSales[0]) }}
            </td>
            <td>
              {{ vueNumberFormat(this.financials.is.costOfSales[1]) }}
            </td>
          </tr>
          <tr>
            <td class="account">Gross Profit</td>
            <td>
              {{ vueNumberFormat(this.financials.is.grossProfit[0]) }}
            </td>
            <td>
              {{ vueNumberFormat(this.financials.is.grossProfit[1]) }}
            </td>
          </tr>
          <tr>
            <td class="account">&nbsp;&nbsp;Gross Margin(%)</td>
            <td>
              {{
                vueNumberFormat(this.financials.is.grossMargin[0], {
                  suffix: '%',
                })
              }}
            </td>
            <td>
              {{
                vueNumberFormat(this.financials.is.grossMargin[1], {
                  suffix: '%',
                })
              }}
            </td>
          </tr>
          <tr>
            <td class="account">Total Operation Profit/(Loss)</td>
            <td>
              {{ vueNumberFormat(this.financials.is.totalOperationProfit[0]) }}
            </td>
            <td>
              {{ vueNumberFormat(this.financials.is.totalOperationProfit[1]) }}
            </td>
          </tr>
          <tr>
            <td class="account">&nbsp;&nbsp;Operating Margin(%)</td>
            <td>
              {{
                vueNumberFormat(
                  (this.financials.is.totalOperationProfit[0] /
                    this.financials.is.totalRevenues[0]) *
                    100,
                  { suffix: '%' }
                )
              }}
            </td>
            <td>
              {{
                vueNumberFormat(
                  (this.financials.is.totalOperationProfit[1] /
                    this.financials.is.totalRevenues[1]) *
                    100,
                  { suffix: '%' }
                )
              }}
            </td>
          </tr>
          <tr>
            <td class="account">EBITDA</td>
            <td>
              {{ vueNumberFormat(this.financials.is.ebitda[0]) }}
            </td>
            <td>
              {{ vueNumberFormat(this.financials.is.ebitda[1]) }}
            </td>
          </tr>
          <tr>
            <td class="account">&nbsp;&nbsp;EBITDA Margin(%)</td>
            <td>
              {{
                vueNumberFormat(this.financials.is.ebitdaMargin[0], {
                  suffix: '%',
                })
              }}
            </td>
            <td>
              {{
                vueNumberFormat(this.financials.is.ebitdaMargin[1], {
                  suffix: '%',
                })
              }}
            </td>
          </tr>
          <tr>
            <td class="account">EBIT</td>
            <td>
              {{ vueNumberFormat(this.financials.is.ebit[0]) }}
            </td>
            <td>
              {{ vueNumberFormat(this.financials.is.ebit[1]) }}
            </td>
          </tr>
          <tr>
            <td class="account">&nbsp;&nbsp;EBIT Margin(%)</td>
            <td>
              {{
                vueNumberFormat(this.financials.is.ebitMargin[0], {
                  suffix: '%',
                })
              }}
            </td>
            <td>
              {{
                vueNumberFormat(this.financials.is.ebitMargin[1], {
                  suffix: '%',
                })
              }}
            </td>
          </tr>
          <tr>
            <td class="account">Net Income</td>
            <td>
              {{ vueNumberFormat(this.financials.is.netIncome[0]) }}
            </td>
            <td>
              {{ vueNumberFormat(this.financials.is.netIncome[1]) }}
            </td>
          </tr>
          <tr>
            <td class="account">&nbsp;&nbsp;Net Margin(%)</td>
            <td>
              {{
                vueNumberFormat(this.financials.is.netMargin[0], {
                  suffix: '%',
                })
              }}
            </td>
            <td>
              {{
                vueNumberFormat(this.financials.is.netMargin[1], {
                  suffix: '%',
                })
              }}
            </td>
          </tr>
          <tr>
            <td class="account">EPS</td>
            <td>
              {{ vueNumberFormat(this.financials.is.eps[0]) }}
            </td>
            <td>
              {{ vueNumberFormat(this.financials.is.eps[1]) }}
            </td>
          </tr>
          <tr>
            <td colspan="3" class="col-head">Cash Flow Statement</td>
          </tr>
          <tr>
            <th>Amounts in millions, USD</th>
            <th>
              FY{{ this.financials.cf.periodDates[0]?.format('YYYY') }}<br />{{
                this.financials.cf.periodDates[0]?.format('DD-MMM-YYYY')
              }}
            </th>
            <th>
              FY{{ this.financials.cf.periodDates[1]?.format('YYYY') }}<br />{{
                this.financials.cf.periodDates[1]?.format('DD-MMM-YYYY')
              }}
            </th>
          </tr>
          <tr>
            <td class="account">Cash Flow from Operating</td>
            <td>
              {{ vueNumberFormat(this.financials.cf.cashflowOperating[0]) }}
            </td>
            <td>
              {{ vueNumberFormat(this.financials.cf.cashflowOperating[1]) }}
            </td>
          </tr>
          <tr>
            <td class="account">Cash Flow from Investing</td>
            <td>
              {{ vueNumberFormat(this.financials.cf.cashflowInvesting[0]) }}
            </td>
            <td>
              {{ vueNumberFormat(this.financials.cf.cashflowInvesting[1]) }}
            </td>
          </tr>
          <tr>
            <td class="account">Cash Flow from Financing</td>
            <td>
              {{ vueNumberFormat(this.financials.cf.cashflowFinancing[0]) }}
            </td>
            <td>
              {{ vueNumberFormat(this.financials.cf.cashflowFinancing[1]) }}
            </td>
          </tr>
          <tr>
            <td class="account">Change in Cash</td>
            <td>
              {{ vueNumberFormat(this.financials.cf.changeInCash[0]) }}
            </td>
            <td>
              {{ vueNumberFormat(this.financials.cf.changeInCash[1]) }}
            </td>
          </tr>
          <tr>
            <td class="account">Capital Expenditure</td>
            <td>
              {{ vueNumberFormat(this.financials.cf.capitalExpenditure[0]) }}
            </td>
            <td>
              {{ vueNumberFormat(this.financials.cf.capitalExpenditure[1]) }}
            </td>
          </tr>
        </tbody>
        </table>
      </div>
      <div class="right">
        <table>
          <tbody>
          <tr>
            <td colspan="10" class="table-name">Transaction History</td>
          </tr>
          <tr>
            <th class="col-head">Date</th>
            <th class="col-head">Transaction Type</th>
            <th class="col-head">Amount ($mm)</th>
            <th class="col-head">Target</th>
            <th class="col-head">Buyer</th>
            <th class="col-head">Raised to Date</th>
            <th class="col-head">Pre-money Valuation</th>
            <th class="col-head">Post Money Valuation</th>
            <th class="col-head">Status</th>
          </tr>
          <tr v-for="(t, index) in this.transactions" v-bind:key="index">
            <td>{{ t.announceDate.format('DD-MMM-YYYY') }}</td>
            <td>{{ t.transactionType }}</td>
            <td style="text-align: right">
              {{
                isNaN(t.transactionValue)
                  ? '-'
                  : vueNumberFormat(t.transactionValue)
              }}
            </td>
            <td
              v-html="this.boldString(t.targetName, this.general.companyName)"
            />
            <td
              v-html="
                t.buyerName == 'Data Unavailable'
                  ? '-'
                  : this.boldString(t.buyerName, this.general.companyName)
              "
            />
            <td style="text-align: right">
              {{ isNaN(t.raised) ? '-' : vueNumberFormat(t.raised) }}
            </td>
            <td style="text-align: right">
              {{ isNaN(t.preMoney) ? '-' : vueNumberFormat(t.preMoney) }}
            </td>
            <td style="text-align: right">
              {{ isNaN(t.postMoney) ? '-' : vueNumberFormat(t.postMoney) }}
            </td>
            <td>{{ t.status }}</td>
          </tr>
        </tbody>
        </table>
        <table>
          <tbody>
          <tr>
            <td colspan="7" class="table-name">Ownership</td>
          </tr>
          <tr>
            <th class="col-head">Holders</th>
            <th class="col-head">% Total Shares</th>
            <th class="col-head">% of Portfolio</th>
            <th class="col-head">Current Shares</th>
            <th class="col-head">Market Value ($mm)</th>
            <th class="col-head">Changes in Shares (QoQ)</th>
            <th class="col-head">Report Date</th>
          </tr>
          <tr v-for="(o, index) in this.ownership" v-bind:key="index">
            <td>{{ o.holderName }}</td>
            <td style="text-align: right">
              {{ vueNumberFormat(o.holderPercent, { suffix: '%' }) }}
            </td>
            <td style="text-align: right">
              {{
                isNaN(o.holderEquity)
                  ? '-'
                  : vueNumberFormat((o.holderValue / o.holderEquity) * 100, {
                      suffix: '%',
                    })
              }}
            </td>
            <td style="text-align: right">
              {{ vueNumberFormat(o.holderShare, { precision: 0 }) }}
            </td>
            <td style="text-align: right">
              {{ vueNumberFormat(o.holderValue) }}
            </td>
            <td style="text-align: right">
              {{ vueNumberFormat(o.changeQoQ) }}
            </td>
            <td>{{ o.positionDate.format('DD-MMM-YYYY') }}</td>
          </tr>
        </tbody>
        </table>
        <table>
          <tbody>
          <tr>
            <td colspan="4" class="table-name">Market Capitalization</td>
          </tr>
          <tr>
            <th class="col-head">Market Cap</th>
            <th class="col-head">Current</th>
            <th class="col-head">% Changes in Market Cap(QoQ)</th>
            <th class="col-head">% Changes in Market Cap(YoY)</th>
          </tr>
          <tr>
            <td style="text-align: right">
              {{ vueNumberFormat(this.marketCap.amount) }}
            </td>
            <td style="text-align: right">
              As of {{ this.marketCap.asOfDate?.format('DD-MMM-YYYY') }}
            </td>
            <td style="text-align: right">
              {{ vueNumberFormat(this.marketCap.changeQoQ, { suffix: '%' }) }}
            </td>
            <td style="text-align: right">
              {{ vueNumberFormat(this.marketCap.changeYoY, { suffix: '%' }) }}
            </td>
          </tr>
        </tbody>
        </table>
        <table>
          <tbody>
          <tr>
            <td colspan="6" class="table-name">Credit Rating</td>
          </tr>
          <tr>
            <th class="col-head">Dept Type</th>
            <th class="col-head">Rating</th>
            <th class="col-head">Rating Date</th>
            <th class="col-head">Last Review Date</th>
            <th class="col-head">CreditWatch/Outlook</th>
            <th class="col-head">CWOL Date</th>
          </tr>
          <tr v-for="(c, index) in this.creditRatings" v-bind:key="index">
            <td>{{ c.deptType }}</td>
            <td>{{ c.rating }}</td>
            <td>
              {{
                c.ratingDate.isValid() ? c.ratingDate.format('DD-MMM-YYYY') : ''
              }}
            </td>
            <td>
              {{
                c.lastReviewDate.isValid()
                  ? c.lastReviewDate.format('DD-MMM-YYYY')
                  : ''
              }}
            </td>
            <td>{{ c.cwol }}</td>
            <td>
              {{ c.cwolDate.isValid() ? c.cwolDate.format('DD-MMM-YYYY') : '' }}
            </td>
          </tr>
        </tbody>
        </table>
        <table>
          <tbody>
          <tr>
            <td colspan="2" class="table-name">Ratings Research</td>
          </tr>
          <tr>
            <td class="col-head">Key strengths</td>
            <td class="col-head">Key risks</td>
          </tr>
          <tr v-for="(k, index) in this.rxResearch.keys" v-bind:key="index">
            <td>{{ k.keyStrength }}</td>
            <td>{{ k.keyRisk }}</td>
          </tr>
          <tr>
            <td colspan="2" v-html="this.rxResearch.abstract" />
          </tr>
        </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
  import requestTemplateFinancials from 'raw-loader!@/assets/dashboard2/inputRequestsFinancials.template';
  import requestTemplateTransactionList from 'raw-loader!@/assets/dashboard2/inputRequestsTransactionList.template';
  import requestTemplateTransactionDetail from 'raw-loader!@/assets/dashboard2/inputRequestsTransactionDetail.template';
  import requestTemplateOwnership from 'raw-loader!@/assets/dashboard2/inputRequestsOwnership.template';
  import requestTemplateMarketCap from 'raw-loader!@/assets/dashboard2/inputRequestsMarketCap.template';
  import api from '@/api/dashboard2.js';
  import { _http } from '@/api/backend.js';

  let jp = require('jsonpath');
  let url = '/gdsapi/rest/v3/clientservice.json';
  let moment = require('moment');
  let compile = require('es6-template-strings/compile');
  let resolveToString = require('es6-template-strings/resolve-to-string');
  const xpath = require('xpath');
  const dom = require('@xmldom/xmldom').DOMParser;
  const Buffer = require('buffer').Buffer;

  export default {
    name: 'DashBoard2',
    data: () => {
      return {
        query: '',
        companyId: '',
        general: {
          companyName: '',
          Title: '',
          Website: '',
          'Number of Employees': '',
          Ticker: '',
          'Year Founded': '',
          'Business Description': '',
        },
        financials: {
          bs: {
            totalAssets: new Array(2),
            currentAssets: new Array(2),
            cashEquivalents: new Array(2),
            nonCurrentAssets: new Array(2),
            totalLiabilities: new Array(2),
            currentLiabilities: new Array(2),
            nonCurrentLiabilities: new Array(2),
            totalEquities: new Array(2),
            periodDates: new Array(2),
          },
          is: {
            totalRevenues: new Array(2),
            costOfSales: new Array(2),
            grossProfit: new Array(2),
            grossMargin: new Array(2),
            totalOperationProfit: new Array(2),
            operatingMargin: new Array(2),
            ebitda: new Array(2),
            ebitdaMargin: new Array(2),
            ebit: new Array(2),
            ebitMargin: new Array(2),
            netIncome: new Array(2),
            netMargin: new Array(2),
            eps: new Array(2),
            periodDates: new Array(2),
          },
          cf: {
            cashflowOperating: new Array(2),
            cashflowInvesting: new Array(2),
            cashflowFinancing: new Array(2),
            changeInCash: new Array(2),
            capitalExpenditure: new Array(2),
            periodDates: new Array(2),
          },
        },
        transactions: [],
        ownership: [],
        marketCap: {
          amount: 0,
          asOfDate: moment(),
          changeQoQ: 0,
          changeYoY: 0,
        },
        creditRatings: [],
        rxResearch: {
          articlePublishedDate: moment(),
          keys: [],
          abstract: '',
        },
        crossReference: [],
      };
    },
    methods: {
      search: async function () {
        let loader = this.$loading.show({
          height: 64,
          width: 64,
          color: '#00ab00',
          backgroundColor: '#4b4b4b',
          isFullPage: true,
          opacity: 0.5,
        });

        try {
          await this.fetchGeneral(this.query, this);
        } catch (e) {
          alert(e);
        }

        try {
          await this.fetchCrossReference(this.companyId, this);
        } catch (e) {
          alert(e);
        }

        try {
          await this.fetchFinancials(this.query, this);
        } catch (e) {
          alert(e);
        }

        try {
          await this.fetchTransactions(this.query, this);
        } catch (e) {
          alert(e);
        }

        try {
          await this.fetchOwnership(this.query, this);
        } catch (e) {
          alert(e);
        }

        try {
          await this.fetchMarketCap(this.query, this);
        } catch (e) {
          alert(e);
        }

        try {
          await this.fetchCreditRating(this);
        } catch (e) {
          alert(e);
        }

        try {
          await this.fetchRXResearch(this);
        } catch (e) {
          alert(e);
        }

        loader.hide();
      },
      fetchGeneral: async (query, self) => {
        const accessToken = localStorage.getItem('accessToken');
        let response = await _http(accessToken).post(url, {
          inputRequests: [
            {
              function: 'GDSP',
              identifier: query,
              mnemonic: 'IQ_COMPANY_NAME',
            },
            {
              function: 'GDSP',
              identifier: query,
              mnemonic: 'IQ_COMPANY_WEBSITE',
            },
            {
              function: 'GDSP',
              identifier: query,
              mnemonic: 'IQ_COMPANY_TICKER',
            },
            {
              function: 'GDSP',
              identifier: query,
              mnemonic: 'IQ_EMPLOYEES',
            },
            {
              function: 'GDSP',
              identifier: query,
              mnemonic: 'IQ_YEAR_FOUNDED',
            },
            {
              function: 'GDSP',
              identifier: query,
              mnemonic: 'IQ_BUSINESS_DESCRIPTION',
            },
            {
              function: 'GDSP',
              identifier: query,
              mnemonic: 'IQ_COMPANY_ID',
            },
          ],
        });
        const result = jp.query(response.data, '$..Row[0]');
        self.general['companyName'] = result[0];
        self.general['Title'] = result[0] + ' (' + result[2] + ')';
        self.general['Website'] = result[1];
        self.general['Number of Employees'] = parseInt(
          result[3]
        ).toLocaleString();
        self.general['Ticker'] = result[2];
        self.general['Year Founded'] = result[4];
        self.general['Business Description'] = result[5];
        self.companyId = result[6];
      },
      fetchFinancials: async (query, self) => {
        let compiled = compile(requestTemplateFinancials);
        let inputRequests = JSON.parse(
          resolveToString(compiled, { query: query })
        );
        let url = '/gdsapi/rest/v3/clientservice.json';
        const accessToken = localStorage.getItem('accessToken');
        let response = await _http(accessToken).post(url, inputRequests);
        self.financials.bs.totalAssets = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_TOTAL_ASSETS')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.bs.currentAssets = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_TOTAL_CA')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.bs.cashEquivalents = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_CASH_EQUIV')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.bs.totalLiabilities = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_TOTAL_LIAB')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.bs.currentLiabilities = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_TOTAL_CL')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.bs.totalEquities = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_TOTAL_EQUITY')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.bs.periodDates = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_PERIODDATE_BS')]..Row[1]"
          )
          .map((x) => moment(x, 'MM/DD/YYYY'));
        self.financials.is.totalRevenues = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_TOTAL_REV')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.is.costOfSales = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_COGS')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.is.grossProfit = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_GP')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.is.grossMargin = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_GROSS_MARGIN')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.is.totalOperationProfit = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_OPER_INC')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.is.ebitda = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_EBITDA')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.is.ebitdaMargin = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_EBITDA_MARGIN')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.is.ebit = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_EBIT')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.is.ebitMargin = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_EBIT_MARGIN')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.is.netIncome = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_NI')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.is.netMargin = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_NI_MARGIN')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.is.eps = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_DILUT_EPS_EXCL')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.is.periodDates = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_PERIODDATE_IS')]..Row[1]"
          )
          .map((x) => moment(x, 'MM/DD/YYYY'));
        self.financials.cf.cashflowOperating = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_CASH_OPER')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.cf.cashflowInvesting = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_CASH_INVEST')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.cf.cashflowFinancing = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_CASH_FINAN')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.cf.changeInCash = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_NET_CHANGE')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.cf.capitalExpenditure = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_CAPEX')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        self.financials.cf.periodDates = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_PERIODDATE_CF')]..Row[1]"
          )
          .map((x) => moment(x, 'MM/DD/YYYY'));
      },
      fetchTransactions: async (query, self) => {
        let compiled = compile(requestTemplateTransactionList);
        let inputRequests = JSON.parse(
          resolveToString(compiled, { query: query })
        );
        const accessToken = localStorage.getItem('accessToken');
        let response = await _http(accessToken).post(url, inputRequests);
        let transactionIds = jp.query(response.data, '$..Row[0]');
        inputRequests = [];
        for (var transactionId of transactionIds) {
          let compiled = compile(requestTemplateTransactionDetail);
          inputRequests = inputRequests.concat(
            JSON.parse(
              resolveToString(compiled, { transactionId: transactionId })
            )
          );
        }
        response = await _http(accessToken).post(url, {
          inputRequests: inputRequests,
        });
        const transactionDetail = jp.query(response.data, '$..Row[0]');
        const chunkSize = inputRequests.length / transactionIds.length;
        self.transactions.splice(0, self.transactions.length);
        for (let i = 0; i < transactionDetail.length; i += chunkSize) {
          let raw = transactionDetail.slice(i, i + chunkSize);
          let transaction = {
            announceDate: moment(raw[0], 'MM/DD/YYYY'),
            transactionType: raw[1],
            transactionValue: parseFloat(raw[2]),
            targetName: raw[3],
            buyerName: raw[4],
            raised: parseFloat(raw[5]),
            preMoney: parseFloat(raw[6]),
            postMoney: parseFloat(raw[7]),
            status: raw[8],
          };
          self.transactions.push(transaction);
        }
      },
      fetchOwnership: async (query, self) => {
        let compiled = compile(requestTemplateOwnership);
        let inputRequests = JSON.parse(
          resolveToString(compiled, { query: query })
        );
        const accessToken = localStorage.getItem('accessToken');
        let response = await _http(accessToken).post(url, inputRequests);
        let ciqIds = jp.query(
          response.data,
          "GDSSDKResponse[?(@.Mnemonic=='IQ_HOLDER_CIQID')]..Row[0]"
        );
        let holderNames = jp.query(
          response.data,
          "GDSSDKResponse[?(@.Mnemonic=='IQ_HOLDER_NAME')]..Row[0]"
        );
        let holderShares = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_HOLDER_SHARES')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        let holderPercent = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_HOLDER_PERCENT')]..Row[0]"
          )
          .map((x) => parseFloat(x));
        let positionDates = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_HOLDER_POSITION_DATE')]..Row[0]"
          )
          .map((x) => moment(x, 'YYYY-MM-DD'));
        let holderValues = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic=='IQ_HOLDER_VALUE')]..Row[0]"
          )
          .map((x) => parseFloat(x));

        // holdingsEquityAssets
        inputRequests = ciqIds.map((id) => ({
          function: 'GDSP',
          identifier: id,
          mnemonic: 'IQ_HOLDINGS_EQUITY_ASSETS',
          properties: {
            currencyId: 'USD',
          },
        }));

        response = await _http(accessToken).post(url, {
          inputRequests: inputRequests,
        });
        let holderEquities = jp
          .query(response.data, '$..Row[0]')
          .map((x) => parseFloat(x));

        // holder shares for last quarter
        inputRequests = positionDates.map((positionDate, index) => ({
          function: 'GDSP',
          identifier: query,
          mnemonic: 'IQ_HOLDER_SHARES',
          properties: {
            asOfDate: moment(positionDate)
              .subtract(3, 'months')
              .format('YYYY/MM/DD'),
            rank: String(index + 1),
          },
        }));

        response = await _http(accessToken).post(url, {
          inputRequests: inputRequests,
        });
        let holderSharesLastQuarter = jp
          .query(response.data, '$..Row[0]')
          .map((x) => parseFloat(x));
        self.ownership.splice(0, self.ownership.length);
        for (let i = 0; i < ciqIds.length; i++) {
          let ownership = {
            ciqId: ciqIds[i],
            holderName: holderNames[i],
            holderShare: holderShares[i],
            holderValue: holderValues[i],
            holderEquity: holderEquities[i],
            holderPercent: holderPercent[i],
            changeQoQ: holderShares[i] - holderSharesLastQuarter[i],
            positionDate: positionDates[i],
          };
          self.ownership.push(ownership);
        }
      },
      fetchMarketCap: async (query, self) => {
        const asOfDate = moment();
        let compiled = compile(requestTemplateMarketCap);
        let inputRequests = JSON.parse(
          resolveToString(compiled, {
            query: query,
            today: moment(asOfDate).format('MM/DD/YYYY'),
            beforeMonth: moment(asOfDate)
              .subtract(1, 'months')
              .format('MM/DD/YYYY'),
            beforeYear: moment(asOfDate)
              .subtract(1, 'years')
              .format('MM/DD/YYYY'),
          })
        );
        const accessToken = localStorage.getItem('accessToken');
        let response = await _http(accessToken).post(url, inputRequests);
        const raw = jp
          .query(response.data, '$..Row[0]')
          .map((x) => parseFloat(x));
        self.marketCap.amount = raw[0];
        self.marketCap.asOfDate = asOfDate;
        self.marketCap.changeQoQ = (1 - raw[1] / raw[0]) * 100;
        self.marketCap.changeYoY = (1 - raw[2] / raw[0]) * 100;
      },
      fetchCreditRating: async (self) => {
        self.creditRatings.splice(0, self.creditRatings.length);

        const accessToken = localStorage.getItem('accessToken');
        let response = await api.fetchCreditRating(self.companyId, accessToken);
        if (response.error) {
          alert('Error while fetching credit rating : ' + response.error);
          return;
        }
        self.creditRatings.push(
          {
            deptType: 'Foreign Currency LT',
            rating:
              response.issuerRatingsResponse
                .issuerCreditRatingForeignCurrencyLongTerm,
            ratingDate: moment(
              response.issuerRatingsResponse
                .issuerCreditRatingForeignCurrencyLongTermDate,
              'YYYY-MM-DD HH:mm:ss'
            ),
            lastReviewDate: moment(
              response.issuerRatingsResponse
                .issuerCreditRatingForeignCurrencyLongTermLastReviewDate,
              'YYYY-MM-DD HH:mm:ss'
            ),
            cwol: response.issuerCwolResponse
              .issuerCreditRatingForeignCurrencyLongTermCWOL,
            cwolDate: moment(
              response.issuerCwolResponse
                .issuerCreditRatingForeignCurrencyLongTermCWOLDate,
              'YYYY-MM-DD HH:mm:ss'
            ),
          },
          {
            deptType: 'Foreign Currency ST',
            rating:
              response.issuerRatingsResponse
                .issuerCreditRatingForeignCurrencyShortTerm,
            ratingDate: moment(
              response.issuerRatingsResponse
                .issuerCreditRatingForeignCurrencyShortTermDate,
              'YYYY-MM-DD HH:mm:ss'
            ),
            lastReviewDate: moment(
              response.issuerRatingsResponse
                .issuerCreditRatingForeignCurrencyShortTermLastReviewDate,
              'YYYY-MM-DD HH:mm:ss'
            ),
            cwol: response.issuerCwolResponse
              .issuerCreditRatingForeignCurrencyShortTermCWOL,
            cwolDate: moment(
              response.issuerCwolResponse
                .issuerCreditRatingForeignCurrencyShortTermCWOLDate,
              'YYYY-MM-DD HH:mm:ss'
            ),
          },
          {
            deptType: 'Local Currency LT',
            rating:
              response.issuerRatingsResponse
                .issuerCreditRatingLocalCurrencyLongTerm,
            ratingDate: moment(
              response.issuerRatingsResponse
                .issuerCreditRatingLocalCurrencyLongTermDate,
              'YYYY-MM-DD HH:mm:ss'
            ),
            lastReviewDate: moment(
              response.issuerRatingsResponse
                .issuerCreditRatingLocalCurrencyLongTermLastReviewDate,
              'YYYY-MM-DD HH:mm:ss'
            ),
            cwol: response.issuerCwolResponse
              .issuerCreditRatingLocalCurrencyLongTermCWOL,
            cwolDate: moment(
              response.issuerCwolResponse
                .issuerCreditRatingLocalCurrencyLongTermCWOLDate,
              'YYYY-MM-DD HH:mm:ss'
            ),
          },
          {
            deptType: 'Local Currency ST',
            rating:
              response.issuerRatingsResponse
                .issuerCreditRatingLocalCurrencyShortTerm,
            ratingDate: moment(
              response.issuerRatingsResponse
                .issuerCreditRatingLocalCurrencyShortTermDate,
              'YYYY-MM-DD HH:mm:ss'
            ),
            lastReviewDate: moment(
              response.issuerRatingsResponse
                .issuerCreditRatingLocalCurrencyShortTermLastReviewDate,
              'YYYY-MM-DD HH:mm:ss'
            ),
            cwol: response.issuerCwolResponse
              .issuerCreditRatingLocalCurrencyShortTermCWOL,
            cwolDate: moment(
              response.issuerCwolResponse
                .issuerCreditRatingLocalCurrencyShortTermCWOLDate,
              'YYYY-MM-DD HH:mm:ss'
            ),
          }
        );
      },
      fetchRXResearch: async (self) => {
        self.rxResearch.keys.splice(0, self.rxResearch.keys.length);
        self.rxResearch.abstract = '';
        const accessToken = localStorage.getItem('accessToken');
        let response = await api.fetchArticleList(self.companyId, accessToken);
        if (response.error) {
          alert('Error while fetching article list : ' + response.error);
          return;
        }
        if (response.length == 0) {
          alert('No research report available.');
          return;
        }
        let articleId = response[0].articleId;
        self.rxResearch.articlePublishedDate = moment(
          response[0].articlePublishedDate,
          'MM/DD/YYYY'
        );

        response = await api.fetchArticleContent(articleId, accessToken);
        if (response.error) {
          alert('Error while fetching article content : ' + response.error);
          return;
        }
        const buffer = Buffer.from(response, 'base64');
        const xml = buffer.toString('utf-8');
        const doc = new dom().parseFromString(xml, 'text/xml');
        let rows = xpath.select(
          '/page/ROWSET/ARTICLE/spdoc/analysis/body/section[@name="Credit Highlights"]/section[1]/table/tablerow',
          doc
        );
        let contentIndex = 2; // bascially the key strengths / risks content will start from index = 2.
        if (rows.length === 0) {
          rows = xpath.select(
            '/page/ROWSET/ARTICLE/spdoc/analysis/body/section[@name="Credit Highlights"]/table/tablerow',
            doc
          );
          contentIndex = 3;
        }
        let keys = [];
        let abstract = `(Publish date: ${self.rxResearch.articlePublishedDate.format(
          'DD-MMM-YYYY'
        )}')<br />`;
        rows.forEach((row, index) => {
          if (index < contentIndex) return;
          let k = {
            keyStrength: row.childNodes[1].textContent,
            keyRisk: row.childNodes[3].textContent,
          };
          keys.push(k);
        });

        if (keys.length === 0) {
          keys = [{ keyStrength: 'No key strength/risk section' }];
        }
        self.rxResearch.keys = keys;

        let section2 = xpath.select(
          '/page/ROWSET/ARTICLE/spdoc/analysis/body/section[@name="Credit Highlights"]/section[2]',
          doc
        )[0];
        if (section2 == undefined) {
          section2 = xpath.select(
            '/page/ROWSET/ARTICLE/spdoc/analysis/body/section[@name="Credit Highlights"]',
            doc
          )[0];
        }
        if (section2 == undefined) {
          abstract += '(No credit highlights section)'
          self.rxResearch.abstract = abstract;
          return;
        }

        Array.from(section2.childNodes).forEach((c) => {
          let nodeName = c.nodeName;
          if (nodeName == 'subsection') {
            let n = c.getAttribute('name');
            let t = c.textContent;
            abstract += `<p><b>${n}</b>&nbsp;${t}</p>`;
          } else if (nodeName == 'para') {
            let t = c.textContent;
            abstract += `<p>${t}</p>`;
          }
        });
        self.rxResearch.abstract = abstract;
      },
      boldString: (str, substr) => {
        let regex = new RegExp(substr, 'g');
        return str.replace(regex, '<b>' + substr + '</b>');
      },
      fetchCrossReference: async (query, self) => {
        self.crossReference.splice(0, self.crossReference.length);

        let inputRequests = [
          'BECRS_ENTITY_BASE',
          'BECRS_ENTITY_FITCH',
          'BECRS_ENTITY_MARKIT_RED',
          'BECRS_ENTITY_MOODY',
          'BECRS_ENTITY_RX',
          'BECRS_ENTITY_BIC_CODE',
        ].map((m) => ({
          function: 'GDSPV',
          identifier: query,
          mnemonic: m,
        }));

        const accessToken = localStorage.getItem('accessToken');
        let response = await _http(accessToken).post(url, {
          inputRequests: inputRequests,
        });
        let entityBase = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic == 'BECRS_ENTITY_BASE')].Rows[?(@.Row[4] == '1' && @.Row[5] == '1')].Row"
          )
          .map((x) => ({
            identifierTypeName: x[2],
            identifierTypeValue: x[3],
          }));
        let entityFitch = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic == 'BECRS_ENTITY_FITCH')].Rows[?(@.Row[4] == '1' && @.Row[5] == '1')].Row"
          )
          .map((x) => ({
            identifierTypeName: x[2],
            identifierTypeValue: x[3],
          }));
        let entityMarkitRed = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic == 'BECRS_ENTITY_MARKIT_RED')].Rows[?(@.Row[4] == '1' && @.Row[5] == '1')].Row"
          )
          .map((x) => ({
            identifierTypeName: x[2],
            identifierTypeValue: x[3],
          }));
        let entityMoody = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic == 'BECRS_ENTITY_MOODY')].Rows[?(@.Row[4] == '1' && @.Row[5] == '1')].Row"
          )
          .map((x) => ({
            identifierTypeName: x[2],
            identifierTypeValue: x[3],
          }));
        let entityRX = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic == 'BECRS_ENTITY_RX')].Rows[?(@.Row[4] == '1' && @.Row[5] == '1')].Row"
          )
          .map((x) => ({
            identifierTypeName: x[2],
            identifierTypeValue: x[3],
          }));
        let entityBIC = jp
          .query(
            response.data,
            "GDSSDKResponse[?(@.Mnemonic == 'BECRS_ENTITY_BIC_CODE')].Rows[?(@.Row[4] == '1' && @.Row[5] == '1')].Row"
          )
          .map((x) => ({
            identifierTypeName: x[2],
            identifierTypeValue: x[3],
          }));
        self.crossReference = [
          ...entityBase,
          ...entityFitch,
          ...entityMarkitRed,
          ...entityMoody,
          ...entityRX,
          ...entityBIC,
        ];
      },
    },
  };
</script>
<style scoped>
  .container {
    width: 1440px;
    margin: 0 auto;
    font-family: Calibri, 'Trebuchet MS', sans-serif;
    font-size: 15px;
  }

  input[type='text'] {
    padding: 10px;
  }

  .wrap.middle {
    padding: 8px;
    padding-bottom: 0px;
  }

  .wrap.middle table {
    margin-top: 8px;
  }

  .wrap.top {
    padding: 8px;
  }

  .wrap .left {
    width: 424px;
    float: left;
    padding: 8px;
  }

  .wrap .right {
    width: 992px;
    float: left;
    padding: 8px 8px 8px 0;
  }

  .wrap .right table {
    margin-bottom: 8px;
  }

  table {
    width: 100%;
    border: 2px solid #444;
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid #444;
    padding: 3px;
  }

  #financials td {
    text-align: right;
  }

  #financials td.account {
    text-align: left;
  }

  #financials td.table-name {
    text-align: left;
  }

  #financials td.col-head {
    text-align: left;
  }

  .table-name {
    font-size: 24px;
    border-bottom: none;
    font-weight: bold;
    text-align: left;
  }

  .col-head {
    background-color: #d6dce4;
    font-weight: bold;
  }

  .col-head.left {
    width: 10%;
    text-align: left;
    float: none;
    padding: 3px;
  }
</style>
